import './style.scss';

var swiperHorizontalInstance = null;
var isMobileView = window.innerWidth < 768;

var initBlockHorizontalSlider = function() {
    const SlideInblocks = document.querySelectorAll('.wp-block-acf-horizontal-scroll-slider');
    
    SlideInblocks.forEach((block, index) => {
        if (!isMobileView) {
            initSliderHorizontal(block, index);
        } 
        initAccordionForMobile(block);
        
    });
};

// Fonction pour l'accordéon sur mobile
var initAccordionForMobile = function(block) {
    const accordionItems = block.querySelectorAll('.swiper-slide');
    console.log('initAccordionForMobile');
    accordionItems.forEach(item => {
        const title = item.querySelector('.title-accordeon');
        const content = item.querySelector('.slide');
        if (title && content) {
            title.addEventListener('click', () => {
                content.classList.toggle('active');
            });
        }
    });
};

// Fonction d'initialisation du slider Swiper pour Desktop
var initSliderHorizontal = function(block, index) {
    var swiperContainerLoop = block.querySelector('.swiper');
    if (!swiperContainerLoop) return;

    swiperContainerLoop.classList.add('swiper-horizontal-' + index);

    swiperHorizontalInstance = new Swiper(block.querySelector('.swiper-horizontal-' + index), {
        direction: 'horizontal',
        loop: false,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 30,
        grabCursor: false,
        mousewheel: {
            enabled: true,
            releaseOnEdges: true,
        },
    });
};

// Fonction pour détruire l'instance du slider Swiper
var destroyHorizontalSlider = function() {
    if (swiperHorizontalInstance !== null) {
        console.log('destroyHorizontalSlider');
        swiperHorizontalInstance.destroy(true, true);
        swiperHorizontalInstance = null;
    }
};

// Gestionnaire de redimensionnement pour basculer entre mobile et desktop
var handleResize = function() {
    const newIsMobileView = window.innerWidth < 768;

    if (newIsMobileView !== isMobileView) {
        isMobileView = newIsMobileView;
        destroyHorizontalSlider(); // Toujours détruire le slider existant
        initBlockHorizontalSlider(); // Réinitialiser le mode approprié
    }
};

// Fonction de déclenchement au chargement du DOM
document.addEventListener('DOMContentLoaded', () => {
    initBlockHorizontalSlider();

    // Gestion pour l'aperçu dans l'éditeur Gutenberg avec ACF
    if (window.acf) {
        window.acf.addAction('render_block_preview/type=horizontal-scroll-slider', function() {
            initBlockHorizontalSlider();
        });
    }
    
    // Gestion du redimensionnement
    window.addEventListener('resize', handleResize);
});
